import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from "./components/navigation/ScrollToTop";
import './assets/css/style.css';
import './assets/css/responsive.css';
import { Navigation } from "./components/navigation/Navigation";
import React from 'react';

AOS.init();

function App() {
  // Google Tag Manager
  useEffect(() => {
    // Add the gtag.js script dynamically
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-26BSDPL0PK';
    scriptTag.async = true;
    document.head.appendChild(scriptTag);

    // Add the inline configuration script
    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-26BSDPL0PK');
    `;
    document.head.appendChild(inlineScript);

    return () => {
      // Cleanup the scripts when the component unmounts
      document.head.removeChild(scriptTag);
      document.head.removeChild(inlineScript);
    };
  }, []);

  // WhatsApp widget configuration
  const phoneNumber = '+31612420600'; // Replace with your phone number
  const message = 'I need some information regarding bookings. Could you please assist me with the details about availability, pricing, or other related inquiries?';
  const encodedMessage = encodeURIComponent(message); // URL encode the message
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  return (
    <>
      <ScrollToTop />
      <ToastContainer
        draggable
        closeButton
        closeOnClick
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
      <Navigation />
      
      {/* WhatsApp Widget */}
      <div className="whatsapp-widget">
        <a
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-button shake"  // Add shake class here
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/a/a7/2062095_application_chat_communication_logo_whatsapp_icon.svg" 
            alt="Chat with us on WhatsApp"
            width="50"
            height="50"
          />
        </a>
      </div>

      {/* Style for WhatsApp widget */}
      <style>
        {`
          .whatsapp-widget {
            position: fixed;
            bottom: 20px;
            right: 20px;
            z-index: 1000; /* Make sure it's on top of other elements */
          }

          .whatsapp-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #25d366; /* WhatsApp green */
            border-radius: 50%;
            padding: 10px;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
            transition: background-color 0.3s;
            animation: shake 2.5s ease-in-out infinite;  /* Apply shake animation */
          }

          .whatsapp-button:hover {
            background-color: #128c7e; /* Darker green on hover */
          }

          .whatsapp-button img {
            border-radius: 50%;
          }

          /* Define the shake animation */
          @keyframes shake {
            0% { transform: translateX(0); }
            25% { transform: translateX(-10px); }
            50% { transform: translateX(10px); }
            75% { transform: translateX(-10px); }
            100% { transform: translateX(0); }
          }
        `}
      </style>
    </>
  );
}

export default App;
