import React, {useState} from 'react';
import {
    FacebookOutlined,
    InstagramOutlined,
    PhoneOutlined,
    SendOutlined,
    WhatsAppOutlined,
    MailOutlined
} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import $ from 'jquery'
import {API_LINK} from "../../components/link/Link";
import {TiLocation} from "react-icons/ti";

function Contact() {
    const {t} = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const submitHandle = async (event) => {
        try {
            if (name && email && phone && message) {
                event.preventDefault();
                event.stopPropagation();
                let toaster;
                var form = $('.contact-form');
                let formdata = new FormData();

                form.find("button").attr("disabled", true);
                toaster = toast.loading("Processing...");
                formdata.append("page", "SendMail");
                formdata.append("name", name)
                formdata.append("email", email)
                formdata.append("phone", phone)
                formdata.append("message", message)
                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                await fetch(`${API_LINK}mail.php`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                            console.log(result);
                            form.find("button").attr("disabled", false);
                            toast.update(toaster, {
                                type: "success",
                                render: "Your message has been successfully sent. Our team will contact you soon.",
                                isLoading: false,
                                autoClose: toaster,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                closeButton: true,
                                closeOnClick: true,
                            })
                            setName("")
                            setMessage("")
                            setPhone("")
                            setEmail("")
                        }
                    )
                    .catch(error => console.log('error', error));
            } else {
                toast.warn("Please fill all the required fields.");
            }
        } catch
            (e) {
            console.log(e)
        }
    }

    return (
        <>
            <div className="contact-container">
                <div className="contact-content">
                    <h1>{t("get-in-touch")}</h1>
                    <p>{t("get-in-touch-content")}</p>
                    <div className="contact-socials">
                        <div className="contact-social">
                           <a href="mailto:ben@boenk.nl" > <div className="icon">
                                <MailOutlined/>
                            </div> </a>
                            <div className="icon-title">
                                <h3>{t("email")}</h3>
                                <small>Our friendly team is here to help.</small>
                            </div>
                            <a href="mailto:ben@boenk.nl">ben@boenk.nl</a>
                        </div>
                        <div className="contact-social">
                          <a href="https://maps.app.goo.gl/aK6vrHtvZyRLXYce9">  <div className="icon">
                                <TiLocation/>
                            </div></a>
                            <div className="icon-title">
                                <h3>{t("location")}</h3>
                                
                            </div>
                            <span>{t("address")}</span>
                        </div>
                        <div className="contact-social">
                           <a href="https://wa.me/+31612420600"> <div className="icon">
                                <PhoneOutlined/>
                            </div></a>
                            <div className="icon-title">
                                <h3>{t("Whatsapp")}</h3>
                                <small>Mon-Fri from 8am to 5pm</small>
                            </div>
                            <a href="https://wa.me/+31612420600">+31 (612) 420600</a>
                        </div>
                    </div>
                </div>
                <div className="contact-form">
                    <div className="form-group-row">
                        <div className="form-group">
                            <label>{t("name")} <code>*</code></label>
                            <input type="text" value={name} onChange={event => setName(event.target.value)}/>
                        </div>
                        <div className="form-group">
                            <label>{t("lastname")} <code>*</code></label>
                            <input type="text"/>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>{t("phone")} <code>*</code></label>
                        <input type="number" value={phone} onChange={event => setPhone(event.target.value)}/>
                    </div>
                    <div className="form-group">
                        <label>{t("email")} <code>*</code></label>
                        <input type="text" value={email} onChange={event => setEmail(event.target.value)}/>
                    </div>
                    <div className="form-group">
                        <label>{t("message")} <code>*</code></label>
                        <textarea value={message} rows={5}
                                  onChange={event => setMessage(event.target.value)}></textarea>
                    </div>
                    <div className="form-group">
                        <button className={'btn-book'} style={{width: "100%", borderRadius: "4px"}}
                                onClick={submitHandle}>
                            <SendOutlined/> {t("send")}</button>
                    </div>
                </div>
                <div className="right-circle"></div>
                <div className="right-circle"></div>
                <div className="right-circle"></div>
                <div className="right-circle"></div>
                <div className="right-circle"></div>
                <div className="right-circle"></div>
                <div className="right-circle"></div>
                <div className="right-circle"></div>
                <div className="right-circle"></div>
                <div className="right-circle"></div>
                <div className="right-circle"></div>
                <div className="right-circle"></div>
                {/*<Booking/>*/}
            </div>
        </>
    );
}

export default Contact;
