import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CalendarWidget = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  // Language-to-calendar URL mapping
  const calendarUrls = {
    en: "https://login.smoobu.com/en/cockpit/widget/show-calendar-iframe/741804/c0ec551ba6d4697746f81fe174171031602cc275e1be0620b8b8df7b773a01a5",
    de: "https://login.smoobu.com/de/cockpit/widget/show-calendar-iframe/741804/c0ec551ba6d4697746f81fe174171031602cc275e1be0620b8b8df7b773a01a5",
    fr: "https://login.smoobu.com/fr/cockpit/widget/show-calendar-iframe/741804/c0ec551ba6d4697746f81fe174171031602cc275e1be0620b8b8df7b773a01a5",
    nl: "https://login.smoobu.com/nl/cockpit/widget/show-calendar-iframe/741804/c0ec551ba6d4697746f81fe174171031602cc275e1be0620b8b8df7b773a01a5",
  };

  // Update language when i18n changes
  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  // Get the URL based on the current language
  const calendarUrl = calendarUrls[language] || calendarUrls['en']; // Default to English if no match

  return (
    <div id="smoobuApartment741804de" className="calendarWidget">
      <link
        rel="stylesheet"
        type="text/css"
        href=""
      />

      <div id="smoobuCalendarIframe">
        <iframe
          className="smallDevices"
          height="940px"
          width="580px"
          src={calendarUrl}
          title="Smoobu Calendar"
        />
        <iframe
          className="bigDevices"
          height="600px"
          width="1000px"
          src={calendarUrl}
          title="Smoobu Calendar"
        />
      </div>

      {/* Responsive Styles */}
      <style>
        {`
          
          
          /* Hide the small devices iframe on larger screens */
          @media screen and (min-width: 768px) {
            .smallDevices {
              display: none;
            }
            .bigDevices {
              width: 100%;
            }
          }

          /* Hide the big devices iframe on smaller screens */
          @media screen and (max-width: 767px) {
            .bigDevices {
              display: none;
            }
            .smallDevices {
              width: 100%;
              
            }
          }

          /* Remove iframe borders */
          iframe {
            border: none;
          }
        `}
      </style>
    </div>
  );
};

export default CalendarWidget;
