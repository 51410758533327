import React from 'react';
import {NavLink} from "react-router-dom";
import {CalendarOutlined} from "@ant-design/icons";
import {APT_1, APT_3, APT_39, APT_44, APT_45, APT_46, fullKitchen} from "../../assets/images/Images";
import {useTranslation} from "react-i18next";

function Facility() {
    const {t} = useTranslation();
    return (
        <>
            <section className="section-about" style={{alignItems: "flex-start"}}>
                <div className="section-about-content">
                    <h2>{t("accommodation")}</h2>
                    <div dangerouslySetInnerHTML={{__html: t("accommodation-content")}} />
                    <h4>{t("direction")}</h4>
                    <div dangerouslySetInnerHTML={{__html: t("direction-content")}} />
                    <h4>{t("entertainment")}</h4>
                    <div dangerouslySetInnerHTML={{__html: t("entertainment-content")}} />
                    
                </div>
                <div className="section-about-image">
                    <img src={APT_1} alt="Apartment Image"/>
                    <NavLink to={'/book-now'} className={'btn-book'} style={{ margin: "30px" }}><CalendarOutlined/> {t("book-now")}</NavLink>
                </div>
            </section>
            <section className="section-about" style={{alignItems: "flex-start"}}>
                <div className="section-about-content">
                    <h2>{t("appliance")}</h2>
                    <div dangerouslySetInnerHTML={{__html: t("appliance-content")}} />
                    
                </div>
                <div className="section-about-image">
                    <img src={APT_45} alt="Apartment Image"/>
                    <NavLink to={'/book-now'} className={'btn-book'} style={{ margin: "30px" }}><CalendarOutlined/> {t("book-now")}</NavLink>
                </div>
            </section>
            <section className="section-about" style={{alignItems: "flex-start"}}>
                <div className="section-about-content">
                    <h2>{t("laundryRoom")}</h2>
                    <div dangerouslySetInnerHTML={{__html: t("laundryRoom-content")}} />
                    
                </div>
                <div className="section-about-image">
                    <img src={APT_39} alt="Apartment Image"/>
                    <NavLink to={'/book-now'} className={'btn-book'} style={{ margin: "30px" }}><CalendarOutlined/> {t("book-now")}</NavLink>
                </div>
            </section>
            <section className="section-about" style={{alignItems: "flex-start"}}>
                <div className="section-about-content">
                    <h2>{t("outdoor-space")}</h2>
                    <div dangerouslySetInnerHTML={{__html: t("outdoor-space-content")}} />
                    
                </div>
                <div className="section-about-image">
                    <img src={APT_44} alt="Apartment Image"/>
                    <NavLink to={'/book-now'} className={'btn-book'} style={{ margin: "30px" }}><CalendarOutlined/> {t("book-now")}</NavLink>
                </div>
            </section>
        </>
    );
}

export default Facility;
