import {Route, Routes} from "react-router-dom";
import HomeLayout from "../layouts/HomeLayout";
import Home from "../../screens/home/Home";
import Apartment from "../../screens/apartment/Apartment";
import Contact from "../../screens/contact/Contact";
import Booking from "../widgets/booking/Booking";
import Facility from "../../screens/facility/Facility";

export const Navigation = () => {
    return (
        <Routes>
            <Route element={<HomeLayout/>}>
                <Route path="/" element={<Home/>}/>
                <Route path="/apartment" element={<Apartment/>}/>
                <Route path="/book-now" element={<Booking/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/facilities" element={<Facility/>}/>
            </Route>

        </Routes>
    )
}
