import React from 'react';
import {APT_1, APT_2, livingRoom1} from "../../assets/images/Images";
import {CalendarOutlined, StarFilled, UserOutlined} from "@ant-design/icons";
import {NavLink} from "react-router-dom";
import {MdLocationPin, MdOutlineReviews} from "react-icons/md";
import {CiForkAndKnife} from "react-icons/ci";
import {useTranslation} from "react-i18next";
import {FaSailboat} from "react-icons/fa6";
import Slider from "react-slick";

function Home() {
    const {t, i18n} = useTranslation();
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // arrows : true,
        autoplay: true,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <>

            <div className="header-container">
                <img src={APT_1} alt="Apartment Image"/>
                <div className="header-content">
                    <h2>{t("home_heading")}</h2>
                    <h4>{t("home_tagline")}</h4>
                    <div className="header-buttons">
                        <a href="#testimonials">
                            <StarFilled/>
                            {t("reviews")}
                        </a>
                        <NavLink to={'/contact'}>
                            <UserOutlined/>
                            {t("contact")}
                        </NavLink>
                    </div>
                </div>
            </div>
            <section className="section-about">
                <div className="section-about-content">
                    <h2>{t("home_heading")}</h2>
                    <p>{t("home_p1")}</p>
                    <p>{t("home_p2")}</p>
                    <p>{t("home_p3")}</p>
                    <p>{t("home_p4")}</p>
                    <NavLink to={'/book-now'} className={'btn-book'}><CalendarOutlined/> {t("book-now")}</NavLink>
                </div>
                <div className="section-about-image">
                    <img src={APT_2} alt="Apartment Image"/>
                </div>
            </section>
            <section className="section-facilities">
                <div className="facility">
                    <FaSailboat/>
                    <h4>{t("boat")}</h4>
                    <p>{t("boat-content")}</p>
                </div>
                <div className="facility">
                    <StarFilled/>
                    <h4>{t("superHost")}</h4>
                    <p>{t("superHost-content")}</p>
                </div>
                <div className="facility">
                    <MdLocationPin/>
                    <h4>{t("superbLocation")}</h4>
                    <p>{t("superbLocation-content")}</p>
                </div>
                <NavLink to={'/book-now'} className={'btn-book'}><CalendarOutlined/> {t("book-now")}</NavLink>
            </section>
            <section className="section-testimonial" id={'testimonials'}>
                <h1>{t("testimonials")}</h1>
                <div className="testimonial-cards">
                    <Slider {...settings}>
                        <div className="testimonial-card">
                            <div className="testimonial-card-header">
                                <MdOutlineReviews/>
                            </div>
                            <div className="testimonial-card-content">
                                <p>{t("testimonial-1")}</p>
                                <h4>Dalia</h4>
                                <small>August 2024</small>
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/5_stars.svg/1024px-5_stars.svg.png"
                                    style={{width: "auto", height: "20px", marginTop: "5px"}} alt=""/>
                            </div>
                        </div>
                        <div className="testimonial-card">
                            <div className="testimonial-card-header">
                                <MdOutlineReviews/>
                            </div>
                            <div className="testimonial-card-content">
                                <p>{t("testimonial-2")}</p>
                                <h4>Annika</h4>
                                <small>May 2024</small>
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/5_stars.svg/1024px-5_stars.svg.png"
                                    style={{width: "auto", height: "20px", marginTop: "5px"}} alt=""/>
                            </div>
                        </div>
                        <div className="testimonial-card">
                            <div className="testimonial-card-header">
                                <MdOutlineReviews/>
                            </div>
                            <div className="testimonial-card-content">
                                <p>{t("testimonial-3")}</p>
                                <h4>Bettina</h4>
                                <small>May 2024</small>
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/5_stars.svg/1024px-5_stars.svg.png"
                                    style={{width: "auto", height: "20px", marginTop: "5px"}} alt=""/>
                            </div>
                        </div>
                        <div className="testimonial-card">
                            <div className="testimonial-card-header">
                                <MdOutlineReviews/>
                            </div>
                            <div className="testimonial-card-content">
                                <p>{t("testimonial-4")}</p>
                                <h4>Mareike</h4>
                                <small>April 2024</small>
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/5_stars.svg/1024px-5_stars.svg.png"
                                    style={{width: "auto", height: "20px", marginTop: "5px"}} alt=""/>
                            </div>
                        </div>
                        <div className="testimonial-card">
                            <div className="testimonial-card-header">
                                <MdOutlineReviews/>
                            </div>
                            <div className="testimonial-card-content">
                                <p>{t("testimonial-5")}</p>
                                <h4>Sarah</h4>
                                <small>September 2023</small>
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/5_stars.svg/1024px-5_stars.svg.png"
                                    style={{width: "auto", height: "20px", marginTop: "5px"}} alt=""/>
                            </div>
                        </div>
                        <div className="testimonial-card">
                            <div className="testimonial-card-header">
                                <MdOutlineReviews/>
                            </div>
                            <div className="testimonial-card-content">
                                <p>{t("testimonial-6")}</p>
                                <h4>Lars</h4>
                                <small>May 2024</small>
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/5_stars.svg/1024px-5_stars.svg.png"
                                    style={{width: "auto", height: "20px", marginTop: "5px"}} alt=""/>
                            </div>
                        </div>
                        <div className="testimonial-card">
                            <div className="testimonial-card-header">
                                <MdOutlineReviews/>
                            </div>
                            <div className="testimonial-card-content">
                                <p>{t("testimonial-7")}</p>
                                <h4>Agathe</h4>
                                <small>March 2023</small>
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/5_stars.svg/1024px-5_stars.svg.png"
                                    style={{width: "auto", height: "20px", marginTop: "5px"}} alt=""/>
                            </div>
                        </div>
                        <div className="testimonial-card">
                            <div className="testimonial-card-header">
                                <MdOutlineReviews/>
                            </div>
                            <div className="testimonial-card-content">
                                <p>{t("testimonial-8")}</p>
                                <h4>Daniela</h4>
                                <small>July 2024</small>
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/5_stars.svg/1024px-5_stars.svg.png"
                                    style={{width: "auto", height: "20px", marginTop: "5px"}} alt=""/>
                            </div>
                        </div>
                        <div className="testimonial-card">
                            <div className="testimonial-card-header">
                                <MdOutlineReviews/>
                            </div>
                            <div className="testimonial-card-content">
                                <p>{t("testimonial-9")}</p>
                                <h4>Klaas</h4>
                                <small> March 2024</small>
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/5_stars.svg/1024px-5_stars.svg.png"
                                    style={{width: "auto", height: "20px", marginTop: "5px"}} alt=""/>
                            </div>
                        </div>
                        <div className="testimonial-card">
                            <div className="testimonial-card-header">
                                <MdOutlineReviews/>
                            </div>
                            <div className="testimonial-card-content">
                                <p>{t("testimonial-10")}</p>
                                <h4>Yair </h4>
                                <small>October 2023</small>
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/5_stars.svg/1024px-5_stars.svg.png"
                                    style={{width: "auto", height: "20px", marginTop: "5px"}} alt=""/>
                            </div>
                        </div>
                        <div className="testimonial-card">
                            <div className="testimonial-card-header">
                                <MdOutlineReviews/>
                            </div>
                            <div className="testimonial-card-content">
                                <p>{t("testimonial-11")}</p>
                                <h4>Philippe</h4>
                                <small>January 2024</small>
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/5_stars.svg/1024px-5_stars.svg.png"
                                    style={{width: "auto", height: "20px", marginTop: "5px"}} alt=""/>
                            </div>
                        </div>
                      
                    </Slider>
                </div>
            </section>
        </>
    );
}

export default Home;
