import {Outlet} from 'react-router-dom';
import {Menu} from "../menu/Menu";
import Footer from "../footer/Footer";
import {useEffect, useState} from "react";

export default function HomeLayout() {
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000)
    }, []);
    return (
        <>
            {isLoading ?
                <div className="loader_container">
                    <div className="loader"></div>
                </div> : null}
            <div>
                <Menu/>
                <main>
                    <Outlet/>
                </main>
                <Footer/>
            </div>
        </>
    )
}
