import React, {useEffect, useState} from 'react';
import CalendarWidget from "../calendar/CalendarWidget";

const Booking = () => {
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        // setIsLoading(true);
        // Dynamically load the external script when the component is mounted
        const script = document.createElement('script');
        script.src = 'https://login.smoobu.com/js/Settings/BookingToolIframe.js';
        script.async = true;
        script.onload = () => {
            // Initialize the iframe once the script is loaded
            window.BookingToolIframe.initialize({
                url: 'https://login.smoobu.com/en/booking-tool/iframe/211401',
                baseUrl: 'https://login.smoobu.com',
                target: '#apartmentIframeAll',
            });
            // setIsLoading(false)
        };
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return <div className={'iframe-container'}>
        <div className={'iframe-container-content'}>
            <div id="apartmentIframeAll" style={{marginBottom: 50}}></div>
            <CalendarWidget/>
        </div>
    </div>;
};

export default Booking;
