import React from 'react';
import {useTranslation} from "react-i18next";
import {LOGO} from "../../assets/images/Images";
import {MdOutlineEmail} from "react-icons/md";
import {InstagramOutlined} from "@ant-design/icons";

function Footer() {
    const {t} = useTranslation();
    return (
        <footer>
            <div className="footer-content">
                <div className="footer-logo">
                    <img src={LOGO} alt="Company Logo"/>
                </div>
                <div className="footer-description">
                    <p>{t("address")}</p>
                    <div className="footer-social">
                        <a href="mailto:ben@boenk.nl">
                            <MdOutlineEmail/>
                        </a>
                        <a href="https://instagram.com/villafriesland">
                            <InstagramOutlined/>
                        </a>
                    </div>
                </div>
            </div>
            <p>© {t("footer")}</p>
        </footer>
    );
}

export default Footer;
